import React from 'react';

import Layout from '../components/Layout';
import { PageProps, PageState } from '../types';

class Index extends React.Component<PageProps, PageState> {
  constructor(props: PageProps) {
    super(props);

    this.state = {
      loading: true
    };
  }

  componentDidMount() {
    this.setState({
      loading: false
    });
  }

  render() {
    return <Layout loading={this.state.loading}>Hello, World</Layout>;
  }
}

export default Index;
